<div class="card-body">
    <ul ngbNav #nav="ngbNav" class="nav nav-fill nav-pills">
        <li ngbNavItem>
            <a ngbNavLink>Aktiviteler</a>
            <ng-template ngbNavContent>
                <div class="content-wrapper container-xxl p-0">
                    <div class="content-body">
                        <section id="ngx-datatable-kitchen-sink">
                            <core-card-snippet>
                                <h4 class="card-title">Aktivite Listesi</h4>
                                <div class="row">
                                    <div class="firstRow mt-1">
                                        <div style="margin-left:9px;">
                                            <div class="col-12">
                                                <select class="form-control form-select"
                                                    [(ngModel)]="basicSelectedOption">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search mt-1">
                                        <div class="col-12">
                                            <input type="text" class="form-control mr-1" name="filterText"
                                                placeholder="Aktivite Arayın.." [(ngModel)]="filterText"
                                                [ngModelOptions]="{standalone:true}">
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                                placeholder="Kullanıcı Seçiniz " bindLabel="firstName" bindValue="id"
                                                (change)="tableByFiltersId()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{ item.firstName }}
                                                        {{item.lastName}}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="modules" [(ngModel)]="selectedModules"
                                                placeholder="Module Seçiniz " bindLabel="name" bindValue="id"
                                                (change)="tableByFiltersId()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{ item.name }}
                                                       </span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12"
                                            *ngIf="(!firstLevelRestriction && !secondLevelRestriction )">
                                            <div
                                                *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser'">
                                                <ng-select [multiple]="true" [items]="partners"
                                                    [(ngModel)]="selectedPartners" placeholder="Cari Seçiniz"
                                                    bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                        <span class="ng-value-label"> {{ item.name }}</span>
                                                        <span class="ng-value-icon right" (click)="clear(item)"
                                                            aria-hidden="true">×</span>
                                                    </ng-template>
                                                    <ng-template ng-header-tmp>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="billTypes"
                                                [(ngModel)]="selectedBillType" placeholder="Faturalandırma"
                                                bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{ item.name }}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">

                                        </div>
                                    </div>
                                    <div class="row col-12 ml-1 mt-1 mb-2">
                                        <div class="col-1">
                                            <button class="btn btn-info btn-sm ml-1 " title="Excele Aktar" rippleEffect
                                                (click)="exportExcelActivities()">
                                                <i data-feather="file-text"></i>
                                            </button>
                                        </div>
                                        <div class="col-2"></div>
                                        <div id="dateFilter" class="row col-9"
                                            style="display: flex;justify-content: end;">

                                            <form class="form-inline">
                                                <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
                                                [(ngModel)]="selectedDateFilter" placeholder="Aya Göre Filtrele"
                                                bindLabel="name" bindValue="id" (change)="tableByFiltersId()" style="min-width:150px;" class="mr-1">
                                                </ng-select>
                                                <div class="form-group">
                                                    <div class="input-group w-0">
                                                        <input name="datepicker" class="form-control" ngbDatepicker
                                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                            (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                            [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                                            [startDate]="fromDateExample!" />
                                                        <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                            <span class="custom-day" type="datetime"
                                                                [class.focused]="focused" [class.range]="isRange(date)"
                                                                [class.faded]="isHovered(date) || isInside(date)"
                                                                (mouseenter)="hoveredDate = date"
                                                                (mouseleave)="hoveredDate = null">
                                                                {{ date.day }}
                                                            </span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                                            name="dpFromDate"
                                                            [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                                                            (ngModelChange)="fromDateExample = $event; tableByDates();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group ml-sm-2">
                                                    <div class="input-group">
                                                        <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                                            name="dpToDate"
                                                            [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                                                            (ngModelChange)="toDateExample = $event; tableByDates();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <table class="table" id="excel-table-ticketactivity"
                                    style="text-align: center;display: none;">
                                    <tbody>
                                        <tr>
                                            <td>Başlangıç Tarihi</td>
                                            <td>Takip Numarası</td>
                                            <td>Talep Konusu</td>
                                            <td>Açıklama</td>
                                            <td>Danışman Adı</td>
                                            <td>Modül</td>
                                            <td>Süre (saat)</td>
                                            <td>Faturalandırma Durumu</td>
                                            <td>Lokasyon Tipi</td>
                                            <td>Cari Bilgisi</td>
                                            <td>Müşteri Talep Numarası</td>
                                            <td>Talep Durumu</td>
                                            <td>Faturalandırma Durumu</td>
                                        </tr>
                                        <tr *ngFor="let item of ticketActivities">
                                            <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                            <td>{{item.trackingNumber}}</td>
                                            <td [innerHTML]="item.ticketSubject"></td>
                                            <td [innerHTML]="item.description"></td>
                                            <td>{{item.userName}}</td>
                                            <td>{{item.moduleName}}</td>
                                            <td>{{item.time}}</td>
                                            <td *ngIf="item.billTypeId==4">Faturalandırılmadı</td>
                                            <td *ngIf="item.billTypeId==5">Faturalandı</td>
                                            <td *ngIf="item.billTypeId==6">Feragat Edildi</td>
                                            <td>{{item.locationTypeName}}</td>
                                            <td>{{item.partnerName }}</td>
                                            <td>{{item.partnerTicketNo}}</td>
                                            <td>{{item.ticketStatusName}}</td>
                                            <td>{{item.ticketBillTypeName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngx-datatable [rows]="ticketActivities |evoTicketActivity:filterText" 
                                    [rowHeight]="58"
                                    class="bootstrap core-bootstrap" 
                                    [limit]="10" 
                                    [headerHeight]="40"
                                    [footerHeight]="50" 
                                    [scrollbarH]="true" 
                                    [limit]="basicSelectedOption"
                                    (select)="onSelect($event)"
                                    >
                                    <ngx-datatable-column 
                                        [width]="50" 
                                        [sortable]="false" 
                                        [canAutoResize]="false" 
                                        [draggable]="false"
                                        [resizeable]="false">
                                        <ng-template ngx-datatable-header-template 
                                            let-value="value"
                                            let-allRowsSelected="allRowsSelected"
                                            let-selectFn="selectFn">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                                <label class="custom-control-label" for="headerChkbxRef"></label>
                                            </div>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template 
                                            let-rowIndex="rowIndex" 
                                            let-value="value"
                                            let-isSelected="isSelected" 
                                            let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                                    (change)="onCheckboxChange($event,rowIndex)" id="rowChkbxRef{{ rowIndex }}" />
                                                <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.startDate |date:'dd-MM-YYYY HH:mm'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="120">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <a (click)="addActivityId(row.id , row.userId)"
                                                class="badge badge-pill badge-light-success font-small-3 font-weight-bold line-height-2 mb-25"
                                                routerLink="/home/details/{{row.ticketId}}" target="">#{{ name
                                                }}</a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Açıklama" prop="description" [width]="270">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div
                                                [innerHTML]="row.description.length > 40 ? row.description.slice(0, 40) + '...' : row.description">
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Talep Konusu" prop="ticketSubject" [width]="270">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div
                                                [innerHTML]="row.ticketSubject?.length > 25 ? row.ticketSubject.substring(0, 25) + '...' : row.ticketSubject">
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Danışman Adı" prop="userName" [width]="230">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.userName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Modülü" prop="module" [width]="170">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.moduleName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Süre" prop="time" [width]="130">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.time}} Saat
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Faturalandırma Durumu" prop="locationTypeName"
                                        [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items-center">
                                                <div class="badge badge-pill badge-light-danger mr-1"
                                                    *ngIf="row.billTypeId==4">Faturalandırılmadı</div>
                                                <div class="badge badge-pill badge-light-primary mr-1"
                                                    *ngIf="row.billTypeId==5">Faturalandı</div>
                                                <div class="badge badge-pill badge-light-secondary mr-1"
                                                    *ngIf="row.billTypeId==6">Feragat Edildi</div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Lokasyon Tipi" prop="locationTypeName" [width]="180">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.locationTypeName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="180"
                                        *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.partnerName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="İşlemler" [width]="150" [sortable]="false"
                                        *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items- ">
                                                <div ngbDropdown container="body">
                                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                                        id="dropdownBrowserState" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <i data-feather="more-vertical"
                                                            class="text-primary cursor-pointer mr-50"></i>
                                                    </a>
                                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                                        aria-labelledby="dropdownBrowserState">
                                                        <button ngbDropdownItem (click)="deletedTicketActivityId=row.id"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#deleteticketactivity">
                                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </core-card-snippet>



                            <div *ngIf="userRole=='Admin'"
                                class="col-md-12 my-3">
                                <div class="row">
                                    <div class="col-9"></div>
                                    <div style="display: flex;justify-content: end;" class="col-3">

                                        <select class="form-control btn btn-success" name="selectedBillTypeId"
                                            [(ngModel)]="selectedBillTypeId" (ngModelChange)="onSelectBillTypeId()">
                                            <option value="0" selected>Faturalandırma Durumunu Seçiniz</option>
                                            <option value="4"><span
                                                    class="bullet bullet-sm bullet-primary">Faturalanmadı</span>
                                            </option>
                                            <option value="5"><span
                                                    class="bullet bullet-sm bullet-success">Faturalandı</span>
                                            </option>
                                            <option value="6"><span class="bullet bullet-sm bullet-success">Feragat
                                                    Edildi</span>
                                            </option>
                                        </select></div>
                                </div>
                            </div>
                            <ul ngbNav #innav="ngbNav" class="nav nav-fill nav-pills">
                                <li ngbNavItem>
                                    <a ngbNavLink>Danışmanlar</a>
                                    <ng-template ngbNavContent>
                                        <core-card-snippet>
                                            <h4 class="card-title">Çalışma Saatleri Listesi</h4>
                                            <div class="row mb-2">
                                                <div class="firstRow mt-1">
                                                    <div style="margin-left:9px;">
                                                        <div class="col-12">
                                                            <select class="form-control form-select"
                                                                [(ngModel)]="moduleSelectOptions">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ngx-datatable [rows]="userHours" [rowHeight]="58"
                                                class="bootstrap core-bootstrap" [headerHeight]="40" [footerHeight]="50"
                                                [scrollbarH]="true" [limit]="moduleSelectOptions">
                                                <ngx-datatable-column name="Kullanıcı Adı" prop="userName">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.userName}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Mail Adresi" prop="mailAddress"
                                                    [width]="250" [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.email}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Adı" prop="name" [width]="180"
                                                    [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.name}}
                                                    </ng-template>
                                                </ngx-datatable-column>

                                                <ngx-datatable-column name="Başlığı" prop="title" [width]="180"
                                                    [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.title}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Günlük Çalışma Saati" prop="totalHours">
                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                      <span [ngClass]="{'red-cell': row.todayHours < 8, 'green-cell': row.todayHours >= 8}">
                                                        {{ row.todayHours }} / 8
                                                      </span>
                                                    </ng-template>
                                                  </ngx-datatable-column>
                                                <ngx-datatable-column name="Çalışma Saati" prop="totalHours">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.totalHours}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </core-card-snippet>
                                    </ng-template>
                                </li>
                                <li ngbNavItem *ngIf="secondLevelRestriction==false">
                                    <a ngbNavLink>Cariler</a>
                                    <ng-template ngbNavContent>
                                        <core-card-snippet>
                                            <h4 class="card-title">Çalışma Saatleri Listesi</h4>
                                            <ngx-datatable [rows]="partnerHours" [rowHeight]="58"
                                                class="bootstrap core-bootstrap" [headerHeight]="40" [footerHeight]="50"
                                                [scrollbarH]="true" [limit]="moduleSelectOptions">
                                                <div class="row mb-2">
                                                    <div class="firstRow mt-1">
                                                        <div style="margin-left:9px;">
                                                            <div class="col-12">
                                                                <select class="form-control form-select"
                                                                    [(ngModel)]="moduleSelectOptions">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ngx-datatable-column name="Adı" prop="name" [width]="200"
                                                    [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.name}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Mail Adresi" prop="mailAddress"
                                                    [width]="300" [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.email}}
                                                    </ng-template>
                                                </ngx-datatable-column>


                                                <ngx-datatable-column name="Başlığı" prop="title" [width]="250"
                                                    [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.title}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Çalışma Saati" prop="totalHours">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.totalHours}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </core-card-snippet>
                                    </ng-template>
                                </li>
                                <li ngbNavItem *ngIf="secondLevelRestriction==false">
                                    <a ngbNavLink>Moduller</a>
                                    <ng-template ngbNavContent>
                                        <core-card-snippet>
                                            <h4 class="card-title">Çalışma Saatleri Listesi</h4>
                                            <div class="row mb-2">
                                                <div class="firstRow mt-1">
                                                    <div style="margin-left:9px;">
                                                        <div class="col-12">
                                                            <select class="form-control form-select"
                                                                [(ngModel)]="moduleSelectOptions">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ngx-datatable [rows]="modulesHours" [rowHeight]="58"
                                                class="bootstrap core-bootstrap" [headerHeight]="40" [footerHeight]="50"
                                                [scrollbarH]="true" [limit]="moduleSelectOptions">

                                                <ngx-datatable-column name="Adı" prop="name" [width]="200"
                                                    [sortable]="false">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.name}}
                                                    </ng-template>
                                                </ngx-datatable-column>

                                                <ngx-datatable-column name="Çalışma Saati" prop="totalHours">
                                                    <ng-template let-row="row" let-name="value"
                                                        ngx-datatable-cell-template>
                                                        {{row.totalHours}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </core-card-snippet>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="innav" class="mt-1"></div>


                            <div class="modal fade" id="deleteticketactivity" tabindex="-1"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Talep Aktivitesini
                                                Sil</h1>
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                                        </div>
                                        <div class="modal-body">
                                            Kaydı Silmek İstediğinize Emin Misiniz?
                                        </div>
                                        <div class="modal-footer">
                                            <div>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                    (click)="deleteActivity(deletedTicketActivityId)"><i
                                                        data-feather="trash" class="mr-50"></i>Sil</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Onaylar</a>
            <ng-template ngbNavContent>
                <div class="content-wrapper container-xxl p-0">
                    <div class="content-body">
                        <section id="ngx-datatable-kitchen-sink">
                            <core-card-snippet>
                                <h4 class="card-title">Onay Listesi</h4>
                                <div class="row">
                                    <div class="firstRow mt-1">
                                        <div style="margin-left:9px;">
                                            <div class="col-12">
                                                <select class="form-control form-select"
                                                    [(ngModel)]="basicSelectedOption">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search mt-1">
                                        <div class="col-12">
                                            <input type="text" class="form-control mr-1" name="filterTextForEffort"
                                                placeholder="Onay Arayın.." [(ngModel)]="filterTextForEffort"
                                                [ngModelOptions]="{standalone:true}">
                                        </div>
                                    </div>

                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="users"
                                                [(ngModel)]="selectedUsersForEffort" placeholder="Kullanıcı Seçiniz "
                                                bindLabel="firstName" bindValue="id"
                                                (change)="tableByFiltersIdForEffort()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{ item.firstName }}
                                                        {{item.lastName}}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="effortStatuses"
                                                [(ngModel)]="selectedEffortStatuses" placeholder="Durum Seçiniz"
                                                bindLabel="name" bindValue="id" (change)="tableByFiltersIdForEffort()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label"> {{ item.name }}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row col-12 ml-1 mt-1 mb-2">
                                        <div class="col-1">
                                            <button class="btn btn-info btn-sm ml-1 " title="Excele Aktar" rippleEffect
                                                (click)="exportExcelEfforts()">
                                                <i data-feather="file-text"></i>
                                            </button>
                                        </div>
                                        <div class="col-3"></div>

                                        <div id="dateFilter" class="row col-7"
                                            style="display: flex; justify-content: end;">
                                            <form class="form-inline">
                                                <div class="form-group">
                                                    <div class="input-group w-0">
                                                        <input name="datepicker" class="form-control" ngbDatepicker
                                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                            (dateSelect)="onDateSelectionForEffort($event)"
                                                            [displayMonths]="2" [dayTemplate]="rangeSelectionDP"
                                                            outsideDays="hidden"
                                                            [startDate]="fromDateExampleForEffort" />
                                                        <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                            <span class="custom-day" type="datetime"
                                                                [class.focused]="focused"
                                                                [class.range]="isRangeForEffort(date)"
                                                                [class.faded]="isHoveredForEffort(date) || isInsideForEffort(date)"
                                                                (mouseenter)="hoveredDateForEffort = date"
                                                                (mouseleave)="hoveredDateForEffort = null">
                                                                {{ date.day }}
                                                            </span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input #dpFromDate class="form-control" type="date"
                                                            placeholder="dd-MM-yyyy" name="dpFromDate"
                                                            [ngModel]="checkType(fromDateExampleForEffort) ? formatter.format(fromDateExampleForEffort) : fromDateExampleForEffort"
                                                            (ngModelChange)="fromDateExampleForEffort = $event; tableByDatesForEffort();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group ml-sm-2">
                                                    <div class="input-group">
                                                        <input #dpToDate class="form-control" type="date"
                                                            placeholder="dd-MM-yyyy" name="dpToDate"
                                                            [ngModel]="checkType(toDateExampleForEffort) ? formatter.format(toDateExampleForEffort) : toDateExampleForEffort"
                                                            (ngModelChange)="toDateExampleForEffort = $event; tableByDatesForEffort();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <table class="table" id="excel-table-effort" style="text-align: center;display: none;">
                                    <tbody>
                                        <tr>
                                            <td>Başlangıç Tarihi</td>
                                            <td>Takip Numarası</td>
                                            <td>Açıklama</td>
                                            <td>Danışmman Adı</td>
                                            <td>Süre (saat)</td>
                                            <td>Onay Durumu</td>
                                            <td>Onaylayan Partner Bilgisi</td>

                                        </tr>
                                        <tr *ngFor="let item of efforts">
                                            <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                            <td>{{item.trackingNumber}}</td>
                                            <td [innerHTML]="item.description"></td>
                                            <td>{{item.userName}}</td>
                                            <td>{{item.time}}</td>
                                            <td *ngIf="item.statusId==1">Onaylandı</td>
                                            <td *ngIf="item.statusId==2">Onay Bekleniyor</td>
                                            <td *ngIf="item.statusId==3">Reddedildi</td>
                                            <td>{{item.partnerUserName }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <ngx-datatable [rows]="efforts |evoEffort:filterTextForEffort" [rowHeight]="58"
                                    class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40"
                                    [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
                                    <ngx-datatable-column [width]="60" [sortable]="false" [canAutoResize]="false"
                                        [draggable]="false" [resizeable]="false">
                                        <ng-template ngx-datatable-header-template let-value="value"
                                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"
                                                    id="headerChkbxRef" />
                                                <label class="custom-control-label" for="headerChkbxRef"></label>
                                            </div>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex"
                                            let-value="value" let-isSelected="isSelected"
                                            let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    [checked]="isSelected" (change)="onCheckboxChangeFn($event)"
                                                    id="rowChkbxRef{{ rowIndex }}" />
                                                <label class="custom-control-label"
                                                    for="rowChkbxRef{{rowIndex}}"></label>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="170">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.startDate |date:'dd-MM-YYYY HH:mm'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="120">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <a class="badge badge-pill badge-light-info font-small-3 font-weight-bold line-height-2 mb-25"
                                                routerLink="/home/details/{{row.ticketId}}" target="">#{{ name
                                                }}</a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Açıklama" prop="description" [width]="250">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="mt-1"
                                                [innerHTML]="row.description?.length > 40 ? row.description.substring(0, 40) + '...' : row.description">
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Danışman Adı" prop="userName" [width]="190">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.userName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Süre" prop="time" [width]="130">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.time}} Saat
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Onay Durumu" prop="statusId" [width]="130">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items-center">
                                                <div class="badge badge-pill badge-light-success mr-1"
                                                    *ngIf="row.statusId==1">
                                                    Onaylandı</div>
                                                <div class="badge badge-pill badge-light-warning mr-1"
                                                    *ngIf="row.statusId==2">Onay
                                                    Bekleniyor</div>
                                                <div class="badge badge-pill badge-light-danger mr-1"
                                                    *ngIf="row.statusId==3">
                                                    Reddedildi</div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Onaylayan Bilgisi" prop="partnerUserId" [width]="220">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.partnerUserName}}
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="İşlemler" [width]="150" [sortable]="false"
                                        *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items- ">
                                                <div ngbDropdown container="body">
                                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                                        id="dropdownBrowserState" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <i data-feather="more-vertical"
                                                            class="text-primary cursor-pointer mr-50"></i>
                                                    </a>
                                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                                        aria-labelledby="dropdownBrowserState">
                                                        <button ngbDropdownItem (click)="deletedEffortId=row.id"
                                                            data-bs-toggle="modal" data-bs-target="#deleteeffort">
                                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </core-card-snippet>
                            <div class="modal fade" id="deleteeffort" tabindex="-1"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Onay Bilgisini
                                                Sil</h1>
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                                        </div>
                                        <div class="modal-body">
                                            Kaydı Silmek İstediğinize Emin Misiniz?
                                        </div>
                                        <div class="modal-footer">
                                            <div>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                    (click)="deleteEffort(deletedEffortId)"><i data-feather="trash"
                                                        class="mr-50"></i>Sil</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-1"></div>
</div>