import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { PurchaseEnquiry } from 'app/evo/models/purchaseenquiry';
import { PurchaseEnquiryFile } from 'app/evo/models/purchaseenquiryfile';
import { PurchaseEnquiryList } from 'app/evo/models/purchaseenquirylist';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { EvoPurchaseEnquiryService } from 'app/evo/services/purchaseenquiry.service';
import { environment } from 'environments/environment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

const URL =  environment.apiUrl + 'UploadPurchaseDocumentForm';
const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-purchase-enquiry-list',
  templateUrl: './evo-purchase-enquiry-list.component.html',
  styles: [
  ],
  providers:[ConfirmationService]
})
export class EvoPurchaseEnquiryListComponent implements OnInit {

  constructor(
    private purchaseEnquiryService:EvoPurchaseEnquiryService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private userService:EvoUserService,
    private router:Router,
    public formatter: NgbDateParserFormatter,
    private permissionUserService:EvoPermissionUserService,
    private cd:ChangeDetectorRef

  ) { }

  userRole: string = "";
  myId:number=0;
  purchaseEnquiryList:PurchaseEnquiryList[]=[];
  filteredPurchaseEnquiryList:PurchaseEnquiryList[]=[];

  purchaseEnquiry:PurchaseEnquiry = new PurchaseEnquiry();


  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  action:string="Ekle";
  public uploader: FileUploader = null;
  documents:any[]=[];
  public basicSelectedOption: number = 10;
  filterText:string;
  processPending:boolean=false;
  
  users:User[]=[];
  selectedUsers:any[]=[];
  
  dateFilter: any[] = [
    { id: 1, name: "Hepsi" },
    { id: 2, name: "Bu Ay" },
    { id: 3, name: "Geçen Ay" },
    { id: 4, name: "Özel" }

  ];

  selectedDateFilter:any=2;
  considerDateFilter:boolean=true;
  fromDate: Date;
  toDate: Date;
  fromDateExample: any = null;
  toDateExample: any = null;
  backgroundColor:string="default";

  public hoveredDate: NgbDate | null = null;

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();

    this.requiredData();
    this.userRole = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.checkPermissions();
    this.list();
    this.configureFileUploader();

  }

  checkPermissions(){
    
    this.permissionUserService.getPermissionUserListByPermissionId(14, this.myId).subscribe((items: any) => {
      var permissionCheck = items.data;
      if(permissionCheck) {
        this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
        this.router.navigate(['/home']);
      }
    });

    if(this.userRole=='Customer'||this.userRole=='CustomerUser'){
      this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
      this.router.navigate(['/home']);
    }
  }

  list() {

    if(this.userRole=="Admin"){
        this.purchaseEnquiryService.getList().subscribe((res:any)=>{
            this.purchaseEnquiryList = res.data;
            this.filterTable()
            this.informationService.list("Satınalma Talep Listesi Başarıyla Getirildi.");
    
        })
    }
    else{
        this.purchaseEnquiryService.getListByUserId(this.myId).subscribe((res:any)=>{
            this.purchaseEnquiryList = res.data;
            this.filterTable()

            this.informationService.list("Satınalma Talep Listesi Başarıyla Getirildi.");
    
        })
    }


  }

  requiredData(){
    this.userService.getActiveUserList().subscribe((res:any)=>{
      this.users = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });        
    })
  }


  get(id: number) {
    this.uploader.queue = [];
    this.purchaseEnquiryService.get(id).subscribe((res:any)=>{
        this.purchaseEnquiry = res.data;
        console.log(this.purchaseEnquiry);
        const uniqueFiles = new Set<string>();

        this.purchaseEnquiry.documents.forEach((item:any)=>{
          let file = new File([item.content], item.fileName, {type: item.mimeType});

          const fileIdentifier = `${file.name}-${file.size}-${file.type}`;

          if (!uniqueFiles.has(fileIdentifier)) {
            uniqueFiles.add(fileIdentifier);
        
            const fileItem = new FileItem(this.uploader, file, {});
            this.uploader.queue.push(fileItem);
          }
        
        })
    })
  }

  clear(){
    this.purchaseEnquiry=new PurchaseEnquiry();
    this.uploader.queue = [];

  }

  savePurchaseEnquiry(){
    this.processPending = true;
    if(this.userRole!='Admin'){
        this.purchaseEnquiry.requesterId= this.myId
    }

    var realStartDate = new Date(new Date(this.purchaseEnquiry.purchaseDate));
    realStartDate = new Date(realStartDate.setHours(realStartDate.getHours() - (realStartDate.getTimezoneOffset() / 60)));
    var realEndDate = new Date(this.purchaseEnquiry.purchaseDeadline);
    realEndDate = new Date(realEndDate.setHours(realEndDate.getHours() - (realEndDate.getTimezoneOffset() / 60)));

    console.log(realStartDate);
    let formData = new FormData()
    formData.append("Id", this.purchaseEnquiry.id.toString());
    formData.append("Description", this.purchaseEnquiry.description.toString());
    formData.append("RequesterId", this.purchaseEnquiry.requesterId.toString());
    formData.append("PurchaseDate", realStartDate.toISOString());
    formData.append("PurchaseDeadline", realEndDate.toISOString());
    formData.append("PurchaseOrderNo", "1");
    for (let i = 0; i < this.uploader.queue.length; i++) {
      formData.append('Documents', this.uploader.queue[i]._file)
    }

    if(this.purchaseEnquiry.id==0){
        this.addPurchaseEnquiry(formData);
    }
    else{
        this.updatePurchaseEnquiry(formData);
    }
  }

  addPurchaseEnquiry(formData:FormData){
    this.purchaseEnquiryService.add(formData).subscribe((res:any)=>{
        this.informationService.add("Satın alma bilgisi eklendi.");
        setTimeout(()=>{
          this.processPending=false;    
          this.router.navigateByUrl("home/purchase-detail/" + res.data.id).then(() => {
            window.location.reload();
          });          
          this.clear();
        },150);

    },(err)=>{
        this.errorService.errorHandler(err);
        this.processPending=false;

    },()=>{

    })
    

  }

  updatePurchaseEnquiry(formData:FormData){
    this.purchaseEnquiryService.update(formData).subscribe((res:any)=>{
        this.informationService.update("Satın alma bilgisi güncellendi.");

    },(err)=>{
        this.errorService.errorHandler(err);
        this.processPending=false;

    },()=>{
        setTimeout(()=>{          
          this.list();
          this.processPending=false;    
        },150);
    })
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  delete() {

    this.confirmationService.confirm({
      message: 'Bu satın alma bilgisini silmek istediğinize emin misiniz?',
      header: 'Satın Alma Bilgisi Silme',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {
        this.purchaseEnquiryService.delete(this.purchaseEnquiry.id).subscribe((res:any)=>{
            this.informationService.delete("Satın alma bilgisi başarıyla silindi.")
        },(err:any)=>{
            this.errorService.errorHandler(err);
            this.processPending=false;

        },()=>{
            setTimeout(()=>{
              this.list();
              this.processPending=false;    
            },150);
        })

      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Satınalma Talepleri";
    this.helperService.exportExcel(element, title);
  }

  getDocuments(event: any) {
    this.documents.push(this.uploader.queue);
  }


  filterTable(){
    let filterTable = this.purchaseEnquiryList;

    if(this.selectedUsers.length>0){
      filterTable = filterTable.filter(x=> this.selectedUsers.includes(x.requesterId));
    }

    if(this.selectedDateFilter == 1){
      this.considerDateFilter = false;
      this.fromDateExample = null;
      this.toDateExample = null;
      this.toDate = null;
      this.fromDate = null;
    }
    if(this.selectedDateFilter==4){
      this.considerDateFilter = true;
      this.constructDatesBasedOnType();
    }
    if(this.selectedDateFilter == 2 || this.selectedDateFilter == 3 ){
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      var datePipe = new DatePipe("en-US");
      this.considerDateFilter = false;
      if (this.selectedDateFilter == 2) { // For "This Month"
        this.fromDateExample = new Date(now.getFullYear(), now.getMonth(), 1);
        this.toDateExample = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day 0 gives last day of the previous month
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
        filterTable = filterTable.filter(item => {
          var itemStartDate = new Date(item.purchaseDate);
      
          return (
            (itemStartDate.getMonth() === currentMonth && itemStartDate.getFullYear() === currentYear)
          );
        });
      }
      else{
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    
        this.fromDateExample = new Date(lastMonthYear, lastMonth, 1);
        this.toDateExample = new Date(lastMonthYear, lastMonth + 1, 0);
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
        filterTable = filterTable.filter(item => {
          var itemStartDate = new Date(item.purchaseDate);
      
          return (
            (itemStartDate.getMonth() === lastMonth && itemStartDate.getFullYear() === lastMonthYear)
          );
        });

      }
    }

    if(this.considerDateFilter == true){
      if (this.fromDate || this.toDate) {
        filterTable = filterTable.filter(item =>
          new Date(item.purchaseDate) >= new Date(this.fromDate) &&
          new Date(item.purchaseDate) <= new Date(this.toDate)
        );
      }
    }

    this.filteredPurchaseEnquiryList = filterTable;

  }

  constructDatesBasedOnType(){
    var datePipe = new DatePipe("en-US");
    var tempDate = null;
    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {

      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }



  downloadImage(name: string) {

    var fileInfo = new PurchaseEnquiryFile();
    fileInfo.purchaseEnquiryId = this.purchaseEnquiry.id
    fileInfo.name = name;

    this.purchaseEnquiryService.downloadDocument(fileInfo).subscribe((data:any) => {
      const blob = new Blob([data.body as BlobPart], { type: data.body?.type });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download =name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);


    },(error:any)=>{
    });
  }

  configureFileUploader(){
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
  }

  selectedFileUrl: string;

  previewImage(item: any) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.selectedFileUrl = event.target.result;
    };
    reader.readAsDataURL(item.file);
  }

  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
  }

  listenForThemeChanges() {
      window.addEventListener('localStorageChange', () => {
        const backgroundColor = localStorage.getItem('backgroundColor');
        console.log('Background color changed:', backgroundColor);
        this.backgroundColor = backgroundColor;
        // Trigger Angular's change detection to update the view
        this.cd.detectChanges();
        this.applyThemeChanges(); // Apply the new theme instantly
      });
  }

  tableByDates() {
    this.constructDatesBasedOnType();

    if(this.selectedDateFilter!=4){
      this.selectedDateFilter=4;
    }

    this.filterTable();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }


}
