<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Kullanıcılarımın Listesi</h4>
                <div class="row">
                    <div class="col-12 mb-50">
                        <button class="btn btn-primary ml-2 btn-sm" rippleEffect data-bs-toggle="modal"
                            data-bs-target="#addpartneruser" title="Yeni Kullanıcı">
                            <i data-feather="plus" class="sm-1"></i>
                        </button>
                        <button class="btn btn-info btn-sm ml-2" (click)="exportExcel()" rippleEffect
                            title="Excele Aktar">
                            <i data-feather="file-text" class=""></i>
                        </button>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <label class="d-flex align-items-center">Göster
                                <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 d-flex justify-content-md-end">
                        <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                            <input type="text" class="form-control mr-1" name="filterText" placeholder="Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                </div>
                <table class="table" id="table-excel" style="text-align: center;display: none;">
                    <thead>
                        <th>Partner Adı</th>
                        <th>Kullanıcı Adı</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of partnerUsers">
                            <td>{{item.partnerName}}</td>
                            <td>{{item.userName}}</td>
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="partnerUsers |evoPartnerUser:filterText" [rowHeight]="58"
                    class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                    [scrollbarH]="true" [limit]="basicSelectedOption">
                    <ngx-datatable-column [width]="200" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value"
                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="300">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.partnerName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="300">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.userName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <button ngbDropdownItem data-bs-toggle="modal"
                                            data-bs-target="#updatepartneruser" (click)="getPartnerUserById(row.id)"><i
                                                data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></button>
                                        <button ngbDropdownItem (click)="deletedPartnerUserId=row.id"
                                            data-bs-toggle="modal" data-bs-target="#deletepartneruser">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                                <button data-bs-toggle="modal" data-bs-target="#updatepartneruser" ngbDropdownItem
                                    title="Güncelle" (click)="getPartnerUserById(row.id)"><i data-feather="edit"
                                        class="text-primary cursor-pointer"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </core-card-snippet>
            <div class="modal fade" id="addpartneruser" data-bs-backdrop="static" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Partner Kullanıcısı Ekle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış
                            </button>
                        </div>
                        <form class="" #AddPartnerUser="ngForm">
                            <div class="modal-body">
                                <div class="form-group">
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Adı</label>
                                        <input type="text" class="form-control" name="txtUserFirstName" required ngModel
                                            #txtUserFirstName="ngModel">
                                        <small class="text-danger"
                                            *ngIf="txtUserFirstName.invalid&& txtUserFirstName.touched">Kullanıcının Adı
                                            boş
                                            bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Soyadı</label>
                                        <input type="text" class="form-control" name="txtUserLastName" required ngModel
                                            #txtUserLastName="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUserLastName.invalid&& txtUserLastName.touched">Kullanıcının
                                            Soyadı boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Başlığı</label>
                                        <input type="text" class="form-control" name="txtUserTile" required ngModel
                                            #txtUserTile="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUserTile.invalid&& txtUserTile.touched">Kullanıcının
                                            Başlığı boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Mail Adrei</label>
                                        <input type="text" class="form-control" name="txtUserMailAdress" required
                                            ngModel #txtUserMailAdress="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUserMailAdress.invalid&& txtUserMailAdress.touched">Kullanıcının
                                            Mail Adresi boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Telefon Numarası</label>
                                        <input type="text" class="form-control" name="txtUserPhoneNumber" required
                                            ngModel #txtUserPhoneNumber="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUserPhoneNumber.invalid&& txtUserPhoneNumber.touched">Kullanıcının
                                            Telefon Numarası boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Cinsiyeti</label>
                                        <select name="slcGender" class="form-control form-select" #slcGender="ngModel"
                                            required [(ngModel)]="gender.id">
                                            <option *ngFor="let item of genders" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="slcGender.invalid&& slcGender.touched">Kullanıcının
                                            Cinsiyetini seçmeniz gerekiyor!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Rolü</label>
                                        <select name="slcRole" class="form-control form-select" #slcRole="ngModel"
                                            required [(ngModel)]="role.id">
                                            <option *ngFor="let item of roles" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="slcRole.invalid&& slcRole.touched">Kullanıcının
                                            Rolünü seçmeniz gerekiyor!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Adı</label>
                                        <input type="text" class="form-control" name="txtUserName"
                                            #txtUserName="ngModel" required ngModel>
                                        <small class="text-danger"
                                            *ngIf="txtUserName.invalid&& txtUserName.touched">Kullanıcı Adı boş
                                            bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Şifresi</label>
                                        <input type="text" class="form-control" name="txtUserPassword"
                                            #txtUserPassword="ngModel" required ngModel>
                                        <small class="text-danger"
                                            *ngIf="txtUserPassword.invalid&& txtUserPassword.touched">Kullanıcının
                                            Şifresi boş bırakılamaz!</small>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div>
                                    <div *ngIf="!AddPartnerUser.valid;else reachableButtonTemplate">
                                        <button type="button" class="btn btn-success" [disabled]="!AddPartnerUser.valid"
                                            disabled><i data-feather="check" class="mr-50"></i>Kaydet</button>
                                    </div>
                                    <ng-template #reachableButtonTemplate>
                                        <button type="button" class="btn btn-success" [disabled]="!AddPartnerUser.valid || pending==true"
                                            (click)="addPartnerUser(txtUserFirstName.value,txtUserLastName.value,txtUserMailAdress.value,txtUserPhoneNumber.value,txtUserTile.value,txtUserName.value,txtUserPassword.value)"><i
                                                data-feather="check"
                                                class="mr-50"></i>Kaydet</button>
                                    </ng-template>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="updatepartneruser" data-bs-backdrop="static" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Kullanıcı Güncelle
                            </h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış
                            </button>
                        </div>
                        <form class="" #UpdateCustomeUser="ngForm">
                            <div class="modal-body">
                                <div class="form-group">
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Adı</label>
                                        <input type="text" class="form-control" name="txtUpdateUserFirstName" required
                                            [(ngModel)]="partnerUser.firstName" #txtUpdateUserFirstName="ngModel">
                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserFirstName.invalid&& txtUpdateUserFirstName.touched">Kullanıcının
                                            Adı boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Soyadı</label>
                                        <input type="text" class="form-control" name="txtUpdateUserLastName" required
                                            [(ngModel)]="partnerUser.lastName" #txtUpdateUserLastName="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserLastName.invalid&& txtUpdateUserLastName.touched">Kullanıcının
                                            Soyadı boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Başlığı</label>
                                        <input type="text" class="form-control" name="txtUpdateUserTile" required
                                            [(ngModel)]="partnerUser.title" #txtUpdateUserTile="ngModel">
                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserTile.invalid&& txtUpdateUserTile.touched">Kullanıcının
                                            Başlığı boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Mail Adrei</label>
                                        <input type="text" class="form-control" name="txtUpdateUserMailAdress" required
                                            [(ngModel)]="partnerUser.mailAddress" #txtUpdateUserMailAdress="ngModel">
                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserMailAdress.invalid&& txtUpdateUserMailAdress.touched">Kullanıcının
                                            Mail Adresi boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Telefon Numarası</label>
                                        <input type="text" class="form-control" name="txtUpdateUserPhoneNumber" required
                                            [(ngModel)]="partnerUser.phoneNumber" #txtUpdateUserPhoneNumber="ngModel">
                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserPhoneNumber.invalid&& txtUpdateUserPhoneNumber.touched">Kullanıcının
                                            Telefon Numarası boş bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Cinsiyeti</label>
                                        <select name="slcUpdateGender" class="form-control form-select" required
                                            #slcUpdateGender="ngModel" [(ngModel)]="partnerUser.genderId">
                                            <option *ngFor="let item of genders" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="slcUpdateGender.invalid&& slcUpdateGender.touched">Kullanıcının
                                            Cinsiyetini seçmeniz gerekiyor!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Rolü</label>
                                        <select name="slcUpdateRole" class="form-control form-select"
                                            #slcUpdateRole="ngModel" required [(ngModel)]="partnerUser.roleId">
                                            [(ngModel)]="partnerUser.roleId">
                                            <option *ngFor="let item of roles" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="slcUpdateRole.invalid&& slcUpdateRole.touched">Kullanıcının
                                            Rolünü seçmeniz gerekiyor!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcı</label>
                                        <input type="text" class="form-control" name="txtUpdateUserName" required
                                            [(ngModel)]="partnerUser.userName" #txtUpdateUserName="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserName.invalid&& txtUpdateUserName.touched">Kullanıcı Adı
                                            boş
                                            bırakılamaz!</small>
                                    </div>
                                    <div class="row mb-2">
                                        <label for="name-vertical">Kullanıcının Şifresi</label>
                                        <input type="text" class="form-control" name="txtUpdateUserPassword"
                                            [(ngModel)]="partnerUser.password" #txtUpdateUserPassword="ngModel">

                                        <small class="text-danger"
                                            *ngIf="txtUpdateUserPassword.invalid&& txtUpdateUserPassword.touched">Kullanıcı
                                            Şifresi boş
                                            bırakılamaz!</small>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div>
                                    <div *ngIf="!UpdateCustomeUser.valid; else reachableUpdateButtonTemplate">
                                        <button type="button" class="btn btn-success"
                                            [disabled]="!UpdateCustomeUser.valid" disabled><i data-feather="check"
                                                class="mr-50"></i>Kaydet</button>
                                    </div>
                                    <ng-template #reachableUpdateButtonTemplate>
                                        <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                            [disabled]="!UpdateCustomeUser.valid" (click)="updatePartnerUser()"><i
                                                data-feather="check" class="mr-50"
                                                data-bs-dismiss="modal"></i>Kaydet</button>
                                    </ng-template>

                                </div>
                            </div>
                            <h6 class="ml-5">Atanan İzinlerin Listesi</h6>
                            <table class="table table-bordered table-hover" style="text-align: center;">
                                <tbody>
                                    <tr>
                                        <td colspan="2">Talepler Hakkında</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" [checked]="canSeeMyTickets"
                                                (change)="addPermissionUser()" [(ngModel)]="canSeeMyTickets"
                                                class="custom-control-input" id="myTickets" name="myTickets">
                                            <label class="custom-control-label" for="myTickets">
                                                Öncesinde Takip Ettiği Taleplerini Görebilir</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Aktiviteler Hakkında</td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <input type="checkbox" [checked]="canSeeMyActivities"
                                                (change)="addPermissionUser()" [(ngModel)]="canSeeMyActivities"
                                                class="custom-control-input" id="myActivities" name="myActivities">
                                            <label class="custom-control-label" for="myActivities">
                                                Aktiviteleri Görmesini Engelle</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Eforlar Hakkında</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" [checked]="canSeeMyEfforts"
                                                (change)="addPermissionUser()" [(ngModel)]="canSeeMyEfforts"
                                                class="custom-control-input" id="myEfforts" name="myEfforts">
                                            <label class="custom-control-label" for="myEfforts">
                                                Eforları Görmesini Engelle</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="deletepartneruser" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Partner Kullanıcısını Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="deletePartnerUser(deletedPartnerUserId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>