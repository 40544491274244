import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ActivityInquiryDate } from 'app/evo/models/activity-inquiry-date';
import { ActivityInquiryFilterList } from 'app/evo/models/activity-inquiry-filter-list';
import { ActivityInquiryPaginationDate } from 'app/evo/models/activity-inquiry-pagination-date';
import { ActivityInquiryList } from 'app/evo/models/activityinquirylist';
import { Plan } from 'app/evo/models/plan';
import { PlanList } from 'app/evo/models/planlist.model';
import { PlanType } from 'app/evo/models/plantype';
import { User } from 'app/evo/models/user';
import { FilterPipe } from 'app/evo/pipe/filter.pipe';
import { ActivityInquiryService } from 'app/evo/services/activityinquiry.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-all-activities-report',
  templateUrl: './evo-all-activities-report.component.html',
  styleUrls: ['./evo-all-activities-report.component.scss'
  ],
  providers:[FilterPipe]
})
export class EvoAllActivitiesReportComponent implements OnInit,OnDestroy {


  public hoveredDate: NgbDate | null = null;
  public fromDateExample: any;
  public toDateExample: any;

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  previousBackgroundColor:string="default";
  backgroundColor:string="default";

  constructor(private activityInquiryService: ActivityInquiryService,
    private transactionService: EvoTransactionService,
    private userService:EvoUserService,
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    private filterPipe: FilterPipe,
    private cd: ChangeDetectorRef
  ) { 

        this.userRole = this.decodeService.getRole();
        let item = this.decodeService.getRole();
        this.myId = this.decodeService.getUserId();
        if (item != "Admin") {
          this.router.navigate(['/home']);
          this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
          });
        }
        else {
          this.list(this.pageNumber);
    
          this.userService.getList().subscribe((res:any)=>{
            this.users = res.data.map(item => {
              return {
                ...item,  // Spread operator to include all existing properties of the item
                fullName: item.firstName + " " + item.lastName // Adding the fullName property
              };
            });       
          })
    
        }

    }

  public basicSelectedOption: number = 10;
  myId:number;

  activityInquiries: ActivityInquiryList[] =[];
  filteredActivityInquiries:ActivityInquiryList[]=[];
  selectedTypes:any[]=[];
  filterText: string = "";
  deletedPlanId: number;

  fromDate: Date;
  toDate: Date;

  loading:boolean=false;

  type: PlanType = new PlanType();
  typeForFilter: PlanType[] = [
    { id: 1, name: "Destek" },
    { id: 3, name: "İzin" },
    { id: 4, name: "Resmi Tatil" },
    { id:5,  name: "Eğitim"},
    { id:6, name:"Görevlendirme"},
    { id:7, name:"Müşteri Ziyareti"},
    { id:8, name: "Proje"},
    { id:9, name: "Toplantı"},
    {id:10, name:"Diğer"}
  ];

  selectedPlanTypeFilter:any[]=[];
  selectedDateFilter:any=2;
  considerDateFilter:boolean=true;

  
  dateFilter: any[] = [
    { id: 1, name: "Hepsi" },
    { id: 2, name: "Bu Ay" },
    { id: 3, name: "Geçen Ay" },
    { id: 4, name: "Özel" }

  ];

  consultantActivityFilters : any[]=[
    {label:'Aktif',value:"Aktif"},{label:"Pasif",value:"Pasif"}]
 
  selectedConsultantActivityFilter:any[]=["Aktif"];

  consultantFilters:any[]=[];
  selectedConsultantFilter:any[]=[];


  consultantTypeFilters: any[]=[
    {label:'İç Kaynak',value:1},{label:"Dış Kaynak",value:2}
  ]
  selectedConsultantTypeFilter:any[]=[1];
  consultants:any[] = [];


  users:User[] = [];
  selectedUsers:any[]=[];
  userRole:string="";
  pageNumber: number = 1;
  totalItems:number=1;

  activityStartDate : Date = null;
  activityEndDate : Date = null;

  sortColumnName:string=null;
  sortDirection:string=null;

  overlayPosition = { top: 0, left: 0 };
  overlayContent: string = '';
  isOverlayVisible: boolean = false;


  ngOnInit(): void {
    this.tableByFiltersId();

    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();

    this.userService.getActiveUserList().subscribe((items:any)=>{
      this.users = items.data;
      this.consultants = items.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
      this.filterUserType();
    })
  }

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  onMouseEnter(event: MouseEvent, description: string): void {
    this.overlayContent = description; // Set the overlay content

    // Update the position of the overlay based on mouse coordinates
    this.updateOverlayPosition(event);
    this.isOverlayVisible = true;
  }

  // Update the overlay position relative to the mouse position
  onMouseMove(event: MouseEvent): void {
    if (this.isOverlayVisible) {
      this.updateOverlayPosition(event);
    }
  }

  // Update the position of the overlay based on mouse coordinates
  updateOverlayPosition(event: MouseEvent): void {

    const isMenuCollapsed = localStorage.getItem("sidebarCollapsed");

    var mouseX = event.clientX;
    var mouseY = event.clientY;

    if(isMenuCollapsed == "true"){
      mouseX = event.clientX-50;

    }
    else{
      mouseX = event.clientX-200;

    }
    
    this.overlayPosition.left = mouseX + window.scrollX;
    this.overlayPosition.top = mouseY + window.scrollY;
  }

  // Hide the overlay when mouse leaves the description part
  onMouseLeave(): void {
    this.isOverlayVisible = false;
  }

  list(pageNumber:number) {
    this.loading=true;
    var activityDateObject : ActivityInquiryPaginationDate = new ActivityInquiryPaginationDate();
    activityDateObject.activityDate = null;
    activityDateObject.pageNo = pageNumber;
    activityDateObject.pageSize = this.basicSelectedOption;
    activityDateObject.sortedColumn = this.sortColumnName;
    activityDateObject.sortDirection = this.sortDirection;

    var activityInquiryFilters : ActivityInquiryFilterList = new ActivityInquiryFilterList();
    activityInquiryFilters.activeUserChecks = this.selectedConsultantActivityFilter;    
    activityInquiryFilters.selectedUserIds = this.selectedConsultantFilter;
    activityInquiryFilters.activityTypeNames = this.selectedPlanTypeFilter;
    activityInquiryFilters.selectedDateFilter = this.selectedDateFilter;
    activityInquiryFilters.userTypeIds = this.selectedConsultantTypeFilter;
    activityInquiryFilters.startDate = this.activityStartDate;
    activityInquiryFilters.endDate = this.activityEndDate;
    activityDateObject.filterList = activityInquiryFilters;


    if(this.userRole!='Admin'){
        this.activityInquiryService.getListByUserId(this.myId,1,activityDateObject).subscribe((res:any)=>{
            this.activityInquiries = res.data;
            this.totalItems = res.totalItems; // Set total item count
            this.filteredActivityInquiries = this.activityInquiries;
            this.loading=false;

        }, (err) => {
            this.errorService.errorHandler(err);
          })
    }
    else{
        this.activityInquiryService.getList(1,activityDateObject).subscribe((res:any)=>{
            this.activityInquiries = res.data;
            this.totalItems = res.totalItems; // Set total item count
            this.filteredActivityInquiries = this.activityInquiries;
            this.loading=false;


        }, (err) => {
            this.errorService.errorHandler(err);
          })
    }
  }

  onSort(event:any){
    const columnName = event.column.prop; // The property name of the column being sorted
    const sortDirection = event.newValue; // 'asc' or 'desc'
  
    this.sortColumnName = columnName;
    this.sortDirection = sortDirection;

    this.pageNumber=1;
    this.list(1);
  }

  tableByFiltersId() {

    this.pageNumber=1;
    if(this.selectedDateFilter == 1){
      this.fromDateExample = null;
      this.toDateExample = null;
      this.toDate = null;
      this.fromDate = null;
    }
    if(this.selectedDateFilter==4){
      this.constructDatesBasedOnType();
    }

    if(this.selectedDateFilter == 2 || this.selectedDateFilter == 3 ){
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      var datePipe = new DatePipe("en-US");
      this.considerDateFilter = false;
      if (this.selectedDateFilter == 2) { // For "This Month"
        this.fromDateExample = new Date(now.getFullYear(), now.getMonth(), 1);
        this.toDateExample = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day 0 gives last day of the previous month
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
      }
      else{
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    
        this.fromDateExample = new Date(lastMonthYear, lastMonth, 1);
        this.toDateExample = new Date(lastMonthYear, lastMonth + 1, 0);
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');

      }
    }

    this.activityStartDate = new Date(this.fromDate);
    this.activityEndDate = new Date(this.toDate);

    this.list(1);
  }

  
  populateUserFilters(){
      if(this.selectedConsultantActivityFilter.length==2 || this.selectedConsultantActivityFilter.length==0){
          this.userService.getAllUserList().subscribe((res:any)=>{

              this.consultants = res.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
              this.filterUserType();

          })
      }
      else if(this.selectedConsultantActivityFilter.length==1 ){
          if(this.selectedConsultantActivityFilter[0].value=="Aktif"){
              this.userService.getActiveUserList().subscribe((res:any)=>{
                  this.consultants = res.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
                  this.filterUserType();

              })
          }
          else{
              this.userService.getPassiveUserList().subscribe((res:any)=>{
                  this.consultants = res.data.map(user => ({ label: user.firstName + " " + user.lastName, value: user.id,type: user.typeId }));
                  this.filterUserType();

              })
          }
      }

  }

  filterUserType(){
    if(this.selectedConsultantTypeFilter.length==2 || this.selectedConsultantTypeFilter.length==0){
        this.consultantFilters = this.consultants;

    }
    else if(this.selectedConsultantTypeFilter.length==1 ){
        if(this.selectedConsultantTypeFilter[0]==1){
            this.consultantFilters = this.consultants.filter(x=>x.type == 1);


        }
        else{
            this.consultantFilters = this.consultants.filter(x=>x.type == 2);

        }
    }

  }


  onPage(event: any): void {
    this.pageNumber = event.offset + 1;

    this.list(this.pageNumber);
  }


  constructDatesBasedOnType(){
    var datePipe = new DatePipe("en-US");
    var tempDate = null;
    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {

      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    this.constructDatesBasedOnType();

    if(this.selectedDateFilter!=4){
      this.selectedDateFilter=4;
    }

    this.tableByFiltersId();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Planlar";
    this.helperService.exportExcel(element, title);
  }

  handleSelectionChange(event?: any) {
    setTimeout(() => { // Ensure the DOM is updated
      const selectedItems = document.querySelectorAll('.p-multiselect-token .p-multiselect-token-label');
      selectedItems.forEach((item: HTMLElement) => {
        const text = item.textContent;
        if (text.length > 10) {
          const trimmedText = text.substring(0, 10) + '...';
          item.textContent = trimmedText;
        }
      });
    }, 0);
  }
  
  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
  }

  listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

  
}
