import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TicketLink } from '../models/ticketLink';
import { Result } from '../models/Result';
import { SecurityKodDto } from '../models/SecurityKodDto';

@Injectable({
  providedIn: 'root'
})
export class TicketLinkService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  LinkRequest(ticketLink: TicketLink): Observable<Result> {
    return this.httpClient.post<Result>(this.baseApiUrl + "LinkRequest", ticketLink)
  }

  ApplyRequest(securityKodDto: SecurityKodDto): Observable<Result> {
    return this.httpClient.post<Result>(this.baseApiUrl + "ApplyRequest", securityKodDto)
  }

  LogTicketConnectionRequest(id:number): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl + "LogTicketConnectionRequest/"+id)
  }
}