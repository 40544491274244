import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Plan } from 'app/evo/models/plan';
import { PlanList } from 'app/evo/models/planlist.model';
import { PlanType } from 'app/evo/models/plantype';
import { User } from 'app/evo/models/user';
import { FilterPipe } from 'app/evo/pipe/filter.pipe';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-plan-list',
  templateUrl: './evo-plan-list.component.html',
  styles: [
  ],
  providers:[FilterPipe]
})
export class EvoPlanListComponent implements OnInit {


  public hoveredDate: NgbDate | null = null;
  public fromDateExample: any;
  public toDateExample: any;

  constructor(private planService: EvoPlanService,
    private transactionService: EvoTransactionService,
    private userService:EvoUserService,
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    private filterPipe: FilterPipe) { }

  public basicSelectedOption: number = 10;
  myId:number;

  plans: PlanList[] =[];
  filteredPlans:PlanList[]=[];
  selectedTypes:any[]=[];
  filterText: string = "";
  deletedPlanId: number;

  fromDate: Date;
  toDate: Date;

  type: PlanType = new PlanType();
  typeForFilter: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
    { id: 4, name: "Resmi Tatiller" },
  ];

  selectedDateFilter:any=2;
  considerDateFilter:boolean=true;

  
  dateFilter: any[] = [
    { id: 1, name: "Hepsi" },
    { id: 2, name: "Bu Ay" },
    { id: 3, name: "Geçen Ay" },
    { id: 4, name: "Özel" }

  ];

  users:User[] = [];
  selectedUsers:any[]=[];

  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();

      this.userService.getList().subscribe((res:any)=>{
        this.users = res.data.map(item => {
          return {
            ...item,  // Spread operator to include all existing properties of the item
            fullName: item.firstName + " " + item.lastName // Adding the fullName property
          };
        });       
      })

    }
  }

  list() {
    this.planService.getList().subscribe((items: any) => {
      this.plans = items.data.map(item=>{
        return{
          ...item,
          timeDifference: this.calculateDateDifference(item.startDate,item.endDate) // Adding the fullName property

        }
      });
      this.tableByFiltersId();
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.myId;
    this.planService.delete(item.data).subscribe((item: any) => {
      this.informationService.delete(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 1500);
    });
  });
  }


  tableByFiltersId() {

    let filteredItems = this.plans;

    if (this.selectedUsers.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
    }

    if(this.selectedTypes.length > 0){
      filteredItems = filteredItems.filter(item=> this.selectedTypes.includes(item.typeId));
    }

    if (this.filterText) {
      filteredItems = this.filterPipe.transform(filteredItems, this.filterText);
    }

    if(this.selectedDateFilter == 1){
      this.considerDateFilter = false;
      this.fromDateExample = null;
      this.toDateExample = null;
      this.toDate = null;
      this.fromDate = null;
    }
    if(this.selectedDateFilter==4){
      this.considerDateFilter = true;
      this.constructDatesBasedOnType();
    }
    if(this.selectedDateFilter == 2 || this.selectedDateFilter == 3 ){
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      var datePipe = new DatePipe("en-US");
      this.considerDateFilter = false;
      if (this.selectedDateFilter == 2) { // For "This Month"
        this.fromDateExample = new Date(now.getFullYear(), now.getMonth(), 1);
        this.toDateExample = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day 0 gives last day of the previous month
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
        filteredItems = filteredItems.filter(item => {
          var itemStartDate = new Date(item.startDate);
      
          return (
            (itemStartDate.getMonth() === currentMonth && itemStartDate.getFullYear() === currentYear)
          );
        });
      }
      else{
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    
        this.fromDateExample = new Date(lastMonthYear, lastMonth, 1);
        this.toDateExample = new Date(lastMonthYear, lastMonth + 1, 0);
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
        filteredItems = filteredItems.filter(item => {
          var itemStartDate = new Date(item.startDate);
      
          return (
            (itemStartDate.getMonth() === lastMonth && itemStartDate.getFullYear() === lastMonthYear)
          );
        });

      }
    }

    if(this.considerDateFilter == true){
      if (this.fromDate || this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromDate) &&
          new Date(item.startDate) <= new Date(this.toDate)
        );
      }
    }


    this.filteredPlans = filteredItems;
    this.calculateDateDifferenceForAll();
  }


  constructDatesBasedOnType(){
    var datePipe = new DatePipe("en-US");
    var tempDate = null;
    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {

      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
  }

  calculateDateDifference(startDate: string, endDate: string): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const WORKING_START_HOUR = 9; // 9 A.M.
    const WORKING_END_HOUR = 18; // 6 P.M.
    const WORKING_HOURS_PER_DAY = 8; // 8 working hours per day after the break

    let totalMillisecondsInRange = 0;

    while (start < end) {
        const currentDayStart = new Date(start);
        currentDayStart.setHours(WORKING_START_HOUR, 0, 0, 0);

        const currentDayEnd = new Date(start);
        currentDayEnd.setHours(WORKING_END_HOUR, 0, 0, 0);

        // Check if the current day is a weekday
        const isWeekday = currentDayStart.getDay() >= 1 && currentDayStart.getDay() <= 5;

        if (isWeekday) {
            // Calculate overlap within working hours
            const dayStart = start > currentDayStart ? start : currentDayStart;
            const dayEnd = end < currentDayEnd ? end : currentDayEnd;

            if (dayStart < dayEnd) {
                const dailyMilliseconds = dayEnd.getTime() - dayStart.getTime();
                totalMillisecondsInRange += dailyMilliseconds;
            }
        }

        // Move to the next day
        start.setDate(start.getDate() + 1);
        start.setHours(0, 0, 0, 0); // Reset to midnight
    }

    // Subtract break time from each full working day
    const totalMinutes = Math.floor(totalMillisecondsInRange / (1000 * 60));
    const totalHours = Math.floor(totalMinutes / 60);
    const adjustedTotalHours = totalHours - Math.floor(totalHours / WORKING_HOURS_PER_DAY); // 1-hour break per full working day

    // Convert adjusted hours to days, hours, and minutes
    const days = Math.floor(adjustedTotalHours / WORKING_HOURS_PER_DAY);
    const remainingHours = adjustedTotalHours % WORKING_HOURS_PER_DAY;
    const minutes = totalMinutes % 60;

    // Build the result string
    let result = '';
    if (days > 0) {
        result += `${days} gün`;
    }
    if (remainingHours > 0) {
        result += (result ? ', ' : '') + `${remainingHours} saat`;
    }
    if (minutes > 0) {
        result += (result ? ' ve ' : '') + `${minutes} dakika`;
    }

    return result || '0 dakika';
}

  calculateDateDifferenceForAll(): string {
    let totalDays = 0;
    let totalHours = 0;
    let totalMinutes = 0;

    this.filteredPlans.forEach((item: any) => {
        const timeDifference = item.timeDifference; // Example: "5 gün, 6 saat ve 10 dakika"

        // Extract days, hours, and minutes using regex
        const daysMatch = timeDifference.match(/(\d+)\s+gün/); // Matches "5 gün"
        const hoursMatch = timeDifference.match(/(\d+)\s+saat/); // Matches "6 saat"
        const minutesMatch = timeDifference.match(/(\d+)\s+dakika/); // Matches "10 dakika"

        const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
        const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
        const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

        // Add to the totals
        totalDays += days;
        totalHours += hours;
        totalMinutes += minutes;
    });

    // Normalize minutes into hours
    totalHours += Math.floor(totalMinutes / 60); // Add overflow minutes to hours
    totalMinutes = totalMinutes % 60; // Remaining minutes after converting to hours

    // Normalize hours into days
    totalDays += Math.floor(totalHours / 8); // Add overflow hours to days (8 hours/day)
    totalHours = totalHours % 8; // Remaining hours after converting to days

    // Build the result string
    let result = '';
    if (totalDays > 0) {
        result += `${totalDays} gün`;
    }
    if (totalHours > 0) {
        result += (result ? ', ' : '') + `${totalHours} saat`;
    }
    if (totalMinutes > 0) {
        result += (result ? ' ve ' : '') + `${totalMinutes} dakika`;
    }

    return result || '0 dakika';
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    this.constructDatesBasedOnType();

    if(this.selectedDateFilter!=4){
      this.selectedDateFilter=4;
    }

    this.tableByFiltersId();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Planlar";
    this.helperService.exportExcel(element, title);
  }
}
