import { Component, OnInit } from '@angular/core';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Plan } from 'app/evo/models/plan';
import { HelperService } from 'app/evo/services/helper.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { Router } from '@angular/router';
import { User } from 'app/evo/models/user';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { PlanType } from 'app/evo/models/plantype';
import { FormBuilder } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-evo-offday-list',
  templateUrl: './evo-offday-list.component.html',
  styles: [
  ]
})
export class EvoOffDayListComponent implements OnInit {

  fromDate: Date;
  toDate: Date;
  public hoveredDate: any = null;
  public fromDateExample: any = null;
  public toDateExample: any = null;


  constructor(private planService: EvoPlanService,
    private userService: EvoUserService,
    private decodeService: DecodeService,
    private helperService: HelperService,
    private informationService: InformationService,
    private errorService: ErrorService,
    private router: Router,
    private formBuilder: FormBuilder,
    public formatter: NgbDateParserFormatter
  ) { }

  public basicSelectedOption: number = 10;
  userVacationDayCounts:any[];
  userId: number;
  filterText: string = "";
  userRole: string;
  deletedPlanId: number;
  plan: Plan = new Plan();

  selectedTypes: any[] = [];

  users: User[] = [];
  vacationDays: Plan[] = [];
  vacationDaysByUserId: Plan[] = [];
  selectedDateFilter:any=2;
  considerDateFilter:boolean=true;

  
  dateFilter: any[] = [
    { id: 1, name: "Hepsi" },
    { id: 2, name: "Bu Ay" },
    { id: 3, name: "Geçen Ay" },
    { id: 4, name: "Özel" }

  ];

  types: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzinler" }
  ];

  filteredVacationDays : Plan[]=[];
  filteredUserVacationDayCounts : any[]=[];
  selectedUsers:any[]=[];

  ngOnInit(): void {
    this.userId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();
    this.userService.getList().subscribe((res:any)=>{
      this.users = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });       
    })


    this.getUserVacationDayCount()


    if (this.userRole == "Admin") {
      this.getVacationDayList();
    }
    else if (this.userRole == "Consultant" || this.userRole == "ConsultantUser") {
      this.getVacationDayListByUserId(this.userId);
    }
    else if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
      this.router.navigate(['/home']);
    }
  }
  user_vacations :any[];
  uservacations(userid : number){
    this.user_vacations = this.vacationDays.filter(p=> p.userId == userid);
  }

  getUserVacationDayCount(){
    this.planService.getUserVacationDayCount().subscribe((res:any)=>{
      this.userVacationDayCounts = res.data;
      this.filteredUserVacationDayCounts = this.userVacationDayCounts;
    })
  }

  getVacationDayList() {

    this.planService.getVacationList().subscribe((items: any) => {
      this.vacationDays = items.data.map((item:any)=>{
        return{
          ...item,
          timeDifference:this.calculateDateDifference(item.startDate,item.endDate)
  
        }
      });
      this.tableByFiltersId();

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });

  }
  getVacationDayListByUserId(userId: number) {
    this.planService.getVacationDayListByUserId(this.userId).subscribe((items: any) => {
      this.vacationDaysByUserId = items.data.map((item:any)=>{
        return{
          ...item,
          timeDifference:this.calculateDateDifference(item.startDate,item.endDate)
  
        }
      });
      
      this.tableByFiltersId();

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "İzin Günleri Listesi";
    this.helperService.exportExcel(element, title);
  }

  updateVacationDays() {
    this.plan.updatedBy = this.userId;
    this.plan.deletedBy = 0;
    this.planService.update(this.plan).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/offdays"]);
        this.ngOnInit();
      }, 1500);
    });
  }

  getMyVacationDay(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      this.plan = item.data;

    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  deleteVacationDays(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.userId;
      this.planService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          if (this.userRole === 'Admin') {
            this.getVacationDayList();
          } else {
            this.getVacationDayListByUserId(this.userId);
          }
        }, 500);
      });
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  tableByFiltersId() {

    let filteredItems = [];
    if(this.userRole=='Admin'){
      filteredItems = this.vacationDays;
    }
    else{
      filteredItems = this.vacationDaysByUserId;

    }

    if (this.selectedUsers.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
    }

    if(this.selectedDateFilter == 1){
      this.considerDateFilter = false;
      this.fromDateExample = null;
      this.toDateExample = null;
      this.toDate = null;
      this.fromDate = null;
    }
    if(this.selectedDateFilter==4){
      this.considerDateFilter = true;
      this.constructDatesBasedOnType();
    }
    if(this.selectedDateFilter == 2 || this.selectedDateFilter == 3 ){
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      var datePipe = new DatePipe("en-US");
      this.considerDateFilter = false;
      if (this.selectedDateFilter == 2) { // For "This Month"
        this.fromDateExample = new Date(now.getFullYear(), now.getMonth(), 1);
        this.toDateExample = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day 0 gives last day of the previous month
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
        filteredItems = filteredItems.filter(item => {
          var itemStartDate = new Date(item.startDate);
      
          return (
            (itemStartDate.getMonth() === currentMonth && itemStartDate.getFullYear() === currentYear)
          );
        });
      }
      else{
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    
        this.fromDateExample = new Date(lastMonthYear, lastMonth, 1);
        this.toDateExample = new Date(lastMonthYear, lastMonth + 1, 0);
        
        this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
        this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
        
        filteredItems = filteredItems.filter(item => {
          var itemStartDate = new Date(item.startDate);
      
          return (
            (itemStartDate.getMonth() === lastMonth && itemStartDate.getFullYear() === lastMonthYear)
          );
        });

      }
    }

    if(this.considerDateFilter == true){
      if (this.fromDate || this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromDate) &&
          new Date(item.startDate) <= new Date(this.toDate)
        );
      }
    }


    this.filteredVacationDays = filteredItems;

    const userIds = Array.from(new Set(this.filteredVacationDays.map(item => item.userId)));


    this.filteredUserVacationDayCounts = this.userVacationDayCounts;
    if(this.selectedUsers.length > 0){
      setTimeout(()=>{
        this.filteredUserVacationDayCounts = this.userVacationDayCounts.filter(item => userIds.includes(item.userId));
      },100);
    }
  }

  calculateDateDifference(startDate: string, endDate: string): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const WORKING_START_HOUR = 9; // 9 A.M.
    const WORKING_END_HOUR = 18; // 6 P.M.
    const WORKING_HOURS_PER_DAY = 8; // 8 working hours per day after the break

    let totalMillisecondsInRange = 0;

    while (start < end) {
        const currentDayStart = new Date(start);
        currentDayStart.setHours(WORKING_START_HOUR, 0, 0, 0);

        const currentDayEnd = new Date(start);
        currentDayEnd.setHours(WORKING_END_HOUR, 0, 0, 0);

        // Check if the current day is a weekday
        const isWeekday = currentDayStart.getDay() >= 1 && currentDayStart.getDay() <= 5;

        if (isWeekday) {
            // Calculate overlap within working hours
            const dayStart = start > currentDayStart ? start : currentDayStart;
            const dayEnd = end < currentDayEnd ? end : currentDayEnd;

            if (dayStart < dayEnd) {
                const dailyMilliseconds = dayEnd.getTime() - dayStart.getTime();
                totalMillisecondsInRange += dailyMilliseconds;
            }
        }

        // Move to the next day
        start.setDate(start.getDate() + 1);
        start.setHours(0, 0, 0, 0); // Reset to midnight
    }

    // Subtract break time from each full working day
    const totalMinutes = Math.floor(totalMillisecondsInRange / (1000 * 60));
    const totalHours = Math.floor(totalMinutes / 60);
    const adjustedTotalHours = totalHours - Math.floor(totalHours / WORKING_HOURS_PER_DAY); // 1-hour break per full working day

    // Convert adjusted hours to days, hours, and minutes
    const days = Math.floor(adjustedTotalHours / WORKING_HOURS_PER_DAY);
    const remainingHours = adjustedTotalHours % WORKING_HOURS_PER_DAY;
    const minutes = totalMinutes % 60;

    // Build the result string
    let result = '';
    if (days > 0) {
        result += `${days} gün`;
    }
    if (remainingHours > 0) {
        result += (result ? ', ' : '') + `${remainingHours} saat`;
    }
    if (minutes > 0) {
        result += (result ? ' ve ' : '') + `${minutes} dakika`;
    }

    return result || '0 dakika';
  }

  constructDatesBasedOnType(){
    var datePipe = new DatePipe("en-US");
    var tempDate = null;
    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {

      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
  }


  
  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    this.constructDatesBasedOnType();

    if(this.selectedDateFilter!=4){
      this.selectedDateFilter=4;
    }

    this.tableByFiltersId();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }


  // checkType(value: any): value is NgbDate {
  //   return value instanceof NgbDate;
  // }


  // tableByDates() {
  //   var tempDate : any = {};
  //   var datePipe = new DatePipe("en-US");

  //   if (this.fromDateExample instanceof NgbDate){
  //     tempDate = {day: this.fromDateExample.day , month:this.fromDateExample.month -1, year:this.fromDateExample.year};
  //   }
  //   else{
  //     var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
  //     tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear()}
  //   }

  //   this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

  //   if (this.toDateExample == null)
  //     this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
  //   else
  //     var tempToDate : any = {};

  //     if (this.toDateExample instanceof NgbDate){
  //       tempToDate = {day: this.toDateExample.day , month:this.toDateExample.month -1, year:this.toDateExample.year};
  //     }
  //     else{
  //       var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
  //       tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear()}
  //     }

  //     this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

  //   if (!this.fromDate == null || !this.toDate == null) {
  //     this.getVacationDayList();
  //   } else {
  //     this.planService.getList().subscribe((items: any) => {
  //       this.vacationDays = items.data.filter(item =>
  //         new Date(item.startDate) >= this.fromDate &&
  //         new Date(item.startDate) <= this.toDate ||
  //         new Date(item.endDate) >= this.fromDate &&
  //         new Date(item.endDate) <= this.toDate
  //       );
  //     });
  //   }
  //   this.tableByFiltersId();
  // }

  // onDateSelection(date: NgbDate) {
  //   if (!this.fromDateExample && !this.toDateExample) {
  //     this.fromDateExample = date;
  //   } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
  //     this.toDateExample = date;
  //   } else {
  //     this.toDateExample = null;
  //     this.fromDateExample = date;
  //   }
  //   this.tableByDates();
  // }


}
